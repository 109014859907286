var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "div",
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "edit-email-modal",
                "hide-footer": "",
                "hide-header": "",
              },
            },
            [
              _c("validation-observer", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ invalid }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-block" },
                            [
                              _c(
                                "b-container",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "form",
                                          {
                                            ref: "form",
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateEmail(
                                                  _vm.editInfo
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required|email",
                                                immediate: "",
                                                name: "Email",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Email Address",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                state:
                                                                  !errors[0],
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.editInfo
                                                                    .email_address,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.editInfo,
                                                                      "email_address",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "editInfo.email_address",
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required",
                                                immediate: "",
                                                name: "Kind",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label: "Kind",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.emailKinds,
                                                                  disabled:
                                                                    _vm.onlyPrimary,
                                                                  state:
                                                                    !errors[0],
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.editInfo
                                                                      .kind,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.editInfo,
                                                                        "kind",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "editInfo.kind",
                                                                },
                                                              }
                                                            ),
                                                            _vm.onlyPrimary
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "There must be at least one primary email"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        type: "submit",
                                        disabled: invalid,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateEmail(_vm.editInfo)
                                        },
                                      },
                                    },
                                    [_vm._v("\n            Save\n          ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3424125420
                ),
              }),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "add-email-modal",
                "hide-footer": "",
                "hide-header": "",
              },
            },
            [
              _c("validation-observer", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ invalid }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-block" },
                            [
                              _c(
                                "b-container",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "form",
                                          {
                                            ref: "form",
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                return _vm.createEmail.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              { attrs: { label: "Email" } },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    rules: "required|email",
                                                    immediate: "",
                                                    name: "Email",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                state:
                                                                  !errors[0],
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newEmail
                                                                    .email_address,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newEmail,
                                                                      "email_address",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newEmail.email_address",
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm.primaryEmailExists
                                              ? _c(
                                                  "b-form-group",
                                                  { attrs: { label: "Kind" } },
                                                  [
                                                    _c("validation-provider", {
                                                      attrs: {
                                                        rules: "required",
                                                        immediate: "",
                                                        name: "Kind",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "b-form-select",
                                                                  {
                                                                    attrs: {
                                                                      options:
                                                                        _vm.emailKinds,
                                                                      state:
                                                                        !errors[0],
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .newEmail
                                                                          .kind,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.newEmail,
                                                                            "kind",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "newEmail.kind",
                                                                    },
                                                                  }
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        type: "submit",
                                        disabled: invalid,
                                      },
                                      on: { click: _vm.createEmail },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Add Email\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2847505004
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }