<template>
  <div v-if="active">
    <b-modal id="edit-email-modal" hide-footer hide-header>
      <validation-observer v-slot="{ invalid }">
        <div class="d-block">
          <b-container>
            <b-row>
              <b-col>
                <form ref="form" @submit.prevent="updateEmail(editInfo)">
                  <validation-provider v-slot="{ errors }" rules="required|email" immediate name="Email">
                    <b-form-group label="Email Address">
                      <b-form-input
                        v-model="editInfo.email_address"
                        :state="!errors[0]"
                        @keydown.enter.prevent
                      />
                      <span>{{ errors[0] }}</span>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" rules="required" immediate name="Kind">
                    <b-form-group label="Kind">
                      <b-form-select v-model="editInfo.kind" :options="emailKinds" :disabled="onlyPrimary" :state="!errors[0]" />
                      <span v-if="onlyPrimary">There must be at least one primary email</span>
                      <span>{{ errors[0] }}</span>
                    </b-form-group>
                  </validation-provider>
                </form>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              type="submit"
              :disabled="invalid"
              @click="updateEmail(editInfo)"
            >
              Save
            </b-button>
          </b-container>
        </div>
      </validation-observer>
    </b-modal>

    <b-modal id="add-email-modal" hide-footer hide-header>
      <validation-observer v-slot="{ invalid }">
        <div class="d-block">
          <b-container>
            <b-row>
              <b-col>
                <form ref="form" @submit.prevent="createEmail">
                  <b-form-group label="Email">
                    <validation-provider v-slot="{ errors }" rules="required|email" immediate name="Email">
                      <b-form-input
                        v-model="newEmail.email_address"
                        :state="!errors[0]"
                        @keydown.enter.prevent
                      />
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group v-if="primaryEmailExists" label="Kind">
                    <validation-provider v-slot="{ errors }" rules="required" immediate name="Kind">
                      <b-form-select v-model="newEmail.kind" :options="emailKinds" :state="!errors[0]" />
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </form>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              type="submit"
              :disabled="invalid"
              @click="createEmail"
            >
              Add Email
            </b-button>
          </b-container>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { email } from 'vee-validate/dist/rules'
import { mapGetters, mapActions } from 'vuex'

extend('email', email)

export default {
  name: 'EmailModal',
  components: { ValidationObserver, ValidationProvider },
  props: {
    bus: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
      email: null,
      name: null,
      editInfo: null,
      newEmail: {
        email_address: null,
        kind: null,
      },
      errors: [],
      emailKinds: ['primary', 'alternate', 'attorney'],
    }
  },
  computed: {
    ...mapGetters('account', ['account', 'primaryEmailExists', 'primaryEmailOnlyOne']),
    onlyPrimary() {
      return this.editInfo.origKind === 'primary' && this.primaryEmailOnlyOne
    },
  },
  async mounted() {
    this.bus.$on('email', this.open)
  },
  methods: {
    ...mapActions('account', ['updatePersonEmail', 'createPersonEmail']),
    async createEmail() {
      if (!this.primaryEmailExists) {
        this.newEmail.kind = 'primary'
      }
      const params = { ...this.newEmail, ...{ person_id: this.account.id } }
      try {
        await this.createPersonEmail(params)
        this.$bvModal.hide('addEmail')
        this.$emit('complete', this.newEmail.email_address)
        this.active = false
      } catch(error) {
        this.errorToast(error.response.data.error.message)
      }
    },
    async updateEmail(email) {
      this.$bvModal.hide('editEmail')
      try {
        await this.updatePersonEmail({ email: email })
        this.$bvModal.hide('editEmail')
        this.$emit('complete')
        this.active = false
      } catch(error) {
        this.errorToast(error.response.data.error.message)
      }
    },
    open(email, name) {
      if (name === 'edit') {
        this.editInfo = email
        this.editInfo.origKind = email.kind
      }
      this.active = true
    },
    errorToast(error) {
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
  },
}
</script>
